// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    font-size: 12px;
  }

  @media (max-width: 767.98px) {
    .footer .row {
      flex-direction: column;
    }

    .footer .row .col-12 {
      margin-bottom: 10px;
    }
  }

  .footer a {
    color: #38aae2;
    text-decoration: none;
  }

  .footer a:hover {
    text-decoration: underline;
  }

  .flip-color {
    color: black !important;
  }

  body[data-theme='dark'] .flip-color {
    color: white !important;
  }

  .ycLogo {
    width: 15px;
    margin-left: 5px;
    border-radius: 2px;
    margin-top: -3px;
  }

  .batch {
    font-size: 10px;
    margin-left: 3px;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB;;EAEA;IACE;MACE,sBAAsB;IACxB;;IAEA;MACE,mBAAmB;IACrB;EACF;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB","sourcesContent":["footer {\n    font-size: 12px;\n  }\n\n  @media (max-width: 767.98px) {\n    .footer .row {\n      flex-direction: column;\n    }\n\n    .footer .row .col-12 {\n      margin-bottom: 10px;\n    }\n  }\n\n  .footer a {\n    color: #38aae2;\n    text-decoration: none;\n  }\n\n  .footer a:hover {\n    text-decoration: underline;\n  }\n\n  .flip-color {\n    color: black !important;\n  }\n\n  body[data-theme='dark'] .flip-color {\n    color: white !important;\n  }\n\n  .ycLogo {\n    width: 15px;\n    margin-left: 5px;\n    border-radius: 2px;\n    margin-top: -3px;\n  }\n\n  .batch {\n    font-size: 10px;\n    margin-left: 3px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
