// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  background-color: var(--bg-color);
}

.navbar-brand {
  font-weight: normal;
  color: #38aae2 !important;
  font-size: 12px;
}

.navbar-brand:hover {
  color: #1f8bb0 !important; /* Optional: a slightly darker shade for hover effect */
}

.nav-link {
  color: #38aae2 !important;
  font-size: 12px;
}

.nav-link:hover {
  color: #1f8bb0 !important; /* Optional: a slightly darker shade for hover effect */
}

.navbar-toggler {
  border: none !important; /* Remove border from hamburger icon */
}

.icon {
  color: #38aae2;
  font-size: 1.5rem;
}

.theme-switcher {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  color: #38aae2;
  font-size: 1.5rem;
  margin-right: 10px; /* Adjust margin as needed */
}

.theme-switcher:hover {
  color: #1f8bb0;
}

@media (max-width: 768px) {
  .theme-switcher {
    margin-right: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;AACnC;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,yBAAyB,EAAE,uDAAuD;AACpF;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,yBAAyB,EAAE,uDAAuD;AACpF;;AAEA;EACE,uBAAuB,EAAE,sCAAsC;AACjE;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,kBAAkB,EAAE,4BAA4B;AAClD;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".navbar {\n  background-color: var(--bg-color);\n}\n\n.navbar-brand {\n  font-weight: normal;\n  color: #38aae2 !important;\n  font-size: 12px;\n}\n\n.navbar-brand:hover {\n  color: #1f8bb0 !important; /* Optional: a slightly darker shade for hover effect */\n}\n\n.nav-link {\n  color: #38aae2 !important;\n  font-size: 12px;\n}\n\n.nav-link:hover {\n  color: #1f8bb0 !important; /* Optional: a slightly darker shade for hover effect */\n}\n\n.navbar-toggler {\n  border: none !important; /* Remove border from hamburger icon */\n}\n\n.icon {\n  color: #38aae2;\n  font-size: 1.5rem;\n}\n\n.theme-switcher {\n  background: none;\n  border: none;\n  padding: 10px;\n  cursor: pointer;\n  color: #38aae2;\n  font-size: 1.5rem;\n  margin-right: 10px; /* Adjust margin as needed */\n}\n\n.theme-switcher:hover {\n  color: #1f8bb0;\n}\n\n@media (max-width: 768px) {\n  .theme-switcher {\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
