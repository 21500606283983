import React from 'react';
import logo from '../assets/images/logo.png'; // Ensure you have a logo.png in the src folder
import appStoreBadge from '../assets/images/app-store-badge.svg'; // Import the SVG file
import playStoreBadge from '../assets/images/google-play-badge.png'; // Import the SVG file
import Meta from '../components/Meta';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/Logo/Logo.css';
import SimpleHeader from '../components/Header/SimpleHeader';

const Home = () => {
  return (
    <>
      <Meta
        title="Connect with people around you - In The Room"
        content="In The Room is an in-person networking and connectivity app for helping people connect in physical spaces. Perfect for networking events, meetups, conferences, festivals, arenas, conference halls, clubs, bars, lounges, and other public places."
        url="https://www.intheroom.social"
      />
      <div style={{ display: 'contents' }}>
        <SimpleHeader />
        <div className="home d-flex justify-content-center align-items-center flex-grow-1">
          <div style={{ textAlign: 'center' }}>
            <img src={logo} alt="Logo" className="logo" />
            <h1 style={{ marginTop: 15, marginBottom: 0, fontSize: 30, fontWeight: '800', color: '#38aae2' }}>In The Room</h1>
            <p style={{ fontWeight: '600', marginBottom: 10, fontSize: 19 }}>Meet People Nearby</p>
            {/* App Store Download Button */}
            <a href="https://testflight.apple.com/join/wDt1SsPt" style={{ display: 'inline-block' }}>
              <img src={appStoreBadge} alt="Download on the App Store" style={{ width: 125, marginRight: 5 }} />
            </a>
            <a href="https://testflight.apple.com/join/wDt1SsPt" style={{ display: 'inline-block' }}>
              <img src={playStoreBadge} alt="Download on the Google Playstore" style={{ width: 140, marginLeft: 5 }} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
