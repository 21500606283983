import React, { useState } from 'react';
import { Form, Button, Container, Spinner, Alert, Dropdown } from 'react-bootstrap';
// import './ContactForm.css';

const ContactForm = () => {
  const [instagram, setInstagram] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await fetch('https://8tnulw7wii.execute-api.us-east-1.amazonaws.com/production/apify/create-user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          instagram_username: instagram,
          linkedin_url: linkedin,
          phone_number: phone,
        }),
      });

      if (response.ok) {
        console.log('Data submitted successfully');
        window.location.href = `sms:${phone}?body=Check%20out%20this%20link:%20http://www.intheroom.social`;
      } else {
        setError('Failed to submit data. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
      console.error('Error submitting data:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    setSearchLoading(true);
    setSearchResults([]);
    try {
      const response = await fetch('https://8tnulw7wii.execute-api.us-east-1.amazonaws.com/production/apify/search-linkedin-profiles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ keyword: searchTerm }),
      });
      const data = await response.json();
      if (data.success) {
        setSearchResults(data.data.items);
      } else {
        setError('No results found. Please try a different name.');
      }
    } catch (err) {
      setError('Error fetching search results. Please try again.');
    } finally {
      setSearchLoading(false);
    }
  };

  const handleSelectProfile = (profileURL) => {
    setLinkedin(profileURL);
    setSearchResults([]);
    // setSearchTerm('');
  };

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">Contact Form</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formInstagram" className="mb-3">
          <Form.Label>Instagram Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="@username"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formLinkedInSearch" className="mb-3">
          <Form.Label>Search LinkedIn Profile</Form.Label>
          <Form.Control
            type="text"
            placeholder="Search for LinkedIn profiles"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button variant="secondary" onClick={handleSearch} disabled={searchLoading}>
            {searchLoading ? 'Searching...' : 'Search'}
          </Button>
          {searchResults.length > 0 && (
            <Dropdown.Menu show className="w-100 mt-2">
              {searchResults.map((profile, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => handleSelectProfile(profile.profileURL)}
                >
                  <img src={profile.profilePicture} alt="" className="rounded-circle me-2" style={{ width: '30px' }} />
                  {profile.fullName} - {profile.headline}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          )}
        </Form.Group>

        <Form.Group controlId="formLinkedIn" className="mb-3">
          <Form.Label>LinkedIn URL</Form.Label>
          <Form.Control
            type="url"
            placeholder="https://linkedin.com/in/your-profile"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPhone" className="mb-3">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="tel"
            placeholder="+1 (555) 555-5555"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="w-100" disabled={loading}>
          {loading ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              {' Sending...'}
            </>
          ) : (
            'Submit'
          )}
        </Button>
      </Form>
    </Container>
  );
};

export default ContactForm;
