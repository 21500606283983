// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-switcher {
  background: none;
  border: none;
  margin-top: 0px;
  cursor: pointer;
  color: #38aae2;
  font-size: 1.3rem;
  text-align: end;
}

.theme-switcher:hover {
  color: #1f8bb0;
}
`, "",{"version":3,"sources":["webpack://./src/components/ThemeSwitcher/ThemeSwitcher.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".theme-switcher {\n  background: none;\n  border: none;\n  margin-top: 0px;\n  cursor: pointer;\n  color: #38aae2;\n  font-size: 1.3rem;\n  text-align: end;\n}\n\n.theme-switcher:hover {\n  color: #1f8bb0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
