// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    max-width: 60px; /* Adjust the width as needed */
  }
`, "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,+BAA+B;EAClD","sourcesContent":[".logo {\n    max-width: 60px; /* Adjust the width as needed */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
